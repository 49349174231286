@use 'sass:meta';


// Override default bootstrap variables before the import
$body-bg: #000;




// Colors

$brand-blue: #132552;
$brand-yellow: #f2b500;
$brand-red: #cc1522;



:root {
    


  --brand-blue-lighter: #{lighten($brand-blue, 10%)};
  --brand-blue-darker: #{darken($brand-blue, 10%)};
  --brand-blue-darker-yet: #{darken($brand-blue, 20%)};

  --brand-yellow-darker: #{darken($brand-yellow, 2%)};
  --brand-yellow-darker-yet: #{darken($brand-yellow, 20%)};

  --brand-blue: #{$brand-blue};
  --brand-yellow: #{$brand-yellow};
  --brand-red: #{$brand-red};


  --brand-red-lighter: #{lighten($brand-red, 5%)};
  --brand-red-darker: #{darken($brand-red, 10%)};
  --brand-red-darker-yet: #{darken($brand-red, 20%)};


  --maximum-page-width: 980;
  --minimum-page-width: 360;
  --maximum-page-width-px: 980px;
  --minimum-page-width-px: 360px;

  --standard-x-padding: 20;
  --standard-y-padding: 20;
  --standard-x-padding-px: 20px;
  --standard-y-padding-px: 20px;

  --main-image-width: 5000;
  --main-image-height: 1408;
  --main-image-width-px: 5000px;
  --main-image-height-px: 1408px;


  --main-image-blurb-width: 380;
  --main-image-blurb-width-px: 380px;


  --header-bar-height: 140;
  --header-bar-height-px: 140px;




}



// Bootstrap Colors


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';




// Fonts

$type-family: halcom, sans-serif;
$logo-family: acier-bat-solid, sans-serif;
$code-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

:root {

  --logo-family: #{meta.inspect($logo-family)};
  --type-family: #{meta.inspect($type-family)};
  --code-family: #{meta.inspect($code-family)};
}




