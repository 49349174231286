
.header {
  width: var(--maximum-page-width-px);
  margin:0 auto;
  height: var( --header-bar-height-px);
  padding:0px 0px 0px var(--standard-x-padding-px);

}

.logo {
  width:120px;
  height: var( --header-bar-height-px);
  vertical-align: top;
  display: inline-block;
  line-height: 136px;
  position: absolute;
  z-index: 100;
  cursor: pointer;
}


.header_right {
  width:calc(100%);
  display: inline-block;
  height: var( --header-bar-height-px);
}



.mobile_menu {
  display: none;
}
.mobile_menu_light {
  display: none;
}

.text_header_items {
  text-align: right;
  vertical-align: middle;
  line-height: 136px;
}
.header_menu_item {
  display: inline-block;
  margin-right: var(--standard-x-padding-px);
}
.header_menu_img {
  display: inline-block;
  margin-top: -3px;
  margin-right:4px;
  filter: brightness(0.0);
}
.header_menu_img_light {
  display: inline-block;
  margin-top: -3px;
  margin-right:4px;
  filter: brightness(1.0);
}
.header_menu_txt {
  display: inline-block;
  color:#000;
  font-weight: 600;
}
.header_menu_txt_light {
  display: inline-block;
  color:#FFF;
  font-weight: 600;
}

.mobile_menu_li {
  padding: var(--standard-y-padding-px) var(--standard-x-padding-px);
  text-align: center;
  background-color: #006cb2;
  margin-bottom: 4px;
}
.mobile_menu_li_light {
  padding: var(--standard-y-padding-px) var(--standard-x-padding-px);
  text-align: center;
  background-color: #006cb2;
  margin-bottom: 4px;
}
.header_mobile_menu_img {
  display: inline-block;
  filter:contrast(100%);
  filter: brightness(100.0);

  
}
.mobile_menu_li_txt {
  display: inline-block;
  text-align: right;
  font-size:20px;
  font-weight: 900;
  color:#FFF;
  margin-left: var(--standard-x-padding-px);
}

.text_header_items {
    display: block;

  }


  .hamburger_icon {
    display: none;

     cursor: pointer;
    background-image: url('../img/hamburger.png');
    background-position: center center;
    background-repeat: no-repeat;
    width: 57px;
    height: calc(140px - 40px);
    margin: 20px 0px;
    float: right;
    background-size: contain;

    filter: brightness(0.0);


    position: absolute;
    z-index: 100;
    right: var(--standard-x-padding-px);

  }

    .hamburger_icon_light {
    display: none;

     cursor: pointer;
    background-image: url('../img/hamburger.png');
    background-position: center center;
    background-repeat: no-repeat;
    width: 57px;
    height: calc(140px - 40px);
    margin: 20px 0px;
    float: right;
    background-size: contain;

    filter: brightness(1.0);


    position: absolute;
    z-index: 100;
    right: var(--standard-x-padding-px);

  }

 .mobile_menu {
  width:100%;

  background-color: rgba(255, 255, 255, 0.5);
  z-index: 80;
  position: absolute;
  top: 0px;
  padding-top: var( --header-bar-height-px);
 }
  .mobile_menu_light {
  width:100%;
  height:140px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 80;
  position: absolute;
  top: 0px;
  padding-top: var( --header-bar-height-px);
 }

@media (max-width: 980px) {


  .header {
    width: 100%;
  }



}


@media (max-width: 720px) {

  .hamburger_icon {
    display: inline-block;
  }
  .hamburger_icon_light {
    display: inline-block;
  }

  .header {
    width: 100%;
  }
  .text_header_items {
    display: none;
  }


}
