
.prairie_server {

  background-image: url('../img/prairie_server2.jpg');
  background-repeat: no-repeat;
  background-position-y: -200px;
  height: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px);
  width:calc(100%);
  background-position-x:  calc(70px + (5000px - 100vw) * -0.421);
  background-size: calc(100% + (5000px - 100vw) * 0.8);

}


.prairie_server_blurb {
  text-align: right;
  width:var(--main-image-blurb-width-px);
  position: absolute;
  top:calc((100vw / var(--main-image-width) * 400 + 1000px - 400px) / 2 - 120px);
  margin-left: calc(var(--maximum-page-width-px) - var(--main-image-blurb-width-px));
  padding-right:20px;
}

.page_label_blurb {
  text-align: left;
  position: absolute;
  margin-left: 0px;
  height:56px;
  top: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - 56px);
  color:#FFF;
  padding-left:20px;
}

@media (max-width: 980px) {


  .prairie_server {
    background-position-x: calc(50.5% + (1px - (100vw / 980)) * 18);
    background-size: calc(100vw / 980 * 3215 + 100%);
    background-position-y:calc( 100vw / 980 * -200 + (100 * (1px - 100vw / 980)) );
    height: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - (268 * (1px - (100vw / 980))));
  }


  .prairie_server_blurb {
    margin-left: calc(100vw - var(--main-image-blurb-width-px) - 20px);
    top:calc((100vw / var(--main-image-width) * 400 + 1000px - 400px) / 2 - 120px - (80 * (1px - (100vw / 980))) );
  }

  .page_label_blurb {
    top: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - (268 * (1px - (100vw / 980))) - 56px);
  }


}
