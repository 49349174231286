.footer_box {
	font-size: 18px;
	line-height: 24px;
	
}
.full_footer {
	margin:0 auto;
	width: var(--maximum-page-width-px);

}
.list {
	width:200px;
	display: inline-block;
	vertical-align: top;
	padding: 40px 20px;

}
.footer_address {
	width: 50%;
	text-align: right;
	float:right;
	color:#FFF;
	padding: 40px 20px;
}
.mobile_footer {
	display: none;
	width:100%;
	text-align: center;

}
.list_head {
	font-size: 22px;

}
.list_head a, .list_head a:hover {
	color:#FFF;
	font-weight: 900;
	text-decoration: none;
}
.list ul {
	list-style-type: none;
	padding-left: 0px;
	text-align: left;
	margin-left:0px;
}
.mobile_list {

}
.mobile_list ul {
	width:300px;
	text-align: center;
	display: inline-block;
}

.mobile_footer .list_head {
background-color: #262626;
  border-bottom: solid 4px #000;
  width:300px;
}

.list_item {

	background-color: #131313;
  border-bottom: solid 4px #000;
  padding:10px;
  font-size: 20px;
  font-weight: 800;
  width:300px;
}

.mobile_list .list_head {
	padding:10px;
}

.social_img {
	margin-right: 20px;
}

.social_img_last {
	margin-right: 0px;
}

.mobile_list {
	width: 100%;
	text-align: center;
}
.mobile_list ul {
	list-style-type: none;
	padding-left: 0px;
}
.mobile_list.list_head {
	width:100%;
}

@media (max-width: 980px) {



  .full_footer {
    width: 100%;
  }


}


@media (max-width: 720px) {



  .full_footer {
    display: none;
  }

  .mobile_footer {
  	display: inline-block;
  	color:#FFF;
  	padding: 40px 20px;
  }

}
