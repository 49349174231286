.home_link, .home_link a {
	color:#FFF;
	text-decoration: none;
}

.home_link a:hover, .home_link:hover {
	
}

.home_link_icon {

}

.third {

  cursor: pointer;
  line-height: 70px;
  text-align: center;
  display: inline-block;
  width:calc(33% - 14px);
  margin-right:20px;
  vertical-align: top;
  margin-bottom: 10px;
}
.last_third {
  cursor: pointer;
  line-height: 70px;
  vertical-align: top;
  text-align: center;
  display: inline-block;
  width:calc(33% - 14px);
  margin-bottom: 10px;
}