

.page {
  margin:-140px auto 0px auto;
  width:var(--maximum-page-width-px);
  padding:0px;
}

.page_regular {
  margin:-140px auto 0px auto;
  width:var(--maximum-page-width-px);
  padding:140px 20px 20px 20px;
}

.center_block_content {
  width:var(--maximum-page-width-px);
  margin:0 auto;
  min-height: 10px;
  padding:20px;
}



.solid_blue_block {
  background-color: #006cb2;
  color:#FFF;
}
.solid_white_block {
  background-color: #FFF;
  color:#000;
}
.solid_black_block {
  background-color: #000;
  color:#FFF;
}
.solid_grey_block {
  background-color: #262626;
  color:#FFF;
}

.patterned_blue_block {
  background-image: url('../img/blue_pattern.png');
  background-repeat: repeat;
  color:#FFF;
}

.patterned_black_block {
  background-image: url('../img/black_pattern.png');
  background-repeat: repeat;
  color:#FFF;
}

.patterned_grey_block {
  background-image: url('../img/grey_pattern.png');
  background-repeat: repeat;
  color:#FFF;
}

.patterned_white_block {
  background-image: url('../img/white_pattern.png');
  background-repeat: repeat;
  color:#000;
}


.left_svg,
.right_svg,
.grey_left_svg,
.grey_right_svg,
.left_svg_full, 
.blue_left_svg, 
.blue_right_svg, 
.blue_left_svg_full, 
.dark_blue_left_svg, 
.dark_blue_right_svg, 
.dark_blue_left_svg_full {
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
  height:60px;

}


.left_svg,
.grey_left_svg,
.left_svg_full,
.grey_left_svg_full,
.blue_left_svg,
.blue_left_svg_full,
.dark_blue_left_svg,
.dark_blue_left_svg_full
 {
  width:90%;
  background-position-x: left;
  padding:14px 14px 14px 28px;
}

.grey_left_svg_full,
.left_svg_full,
.blue_left_svg_full,
.dark_blue_left_svg_full
{
  width:100%;
}

.right_svg,
.grey_right_svg,
.blue_right_svg,
.dark_blue_right_svg
{
  width:10%;
  background-position-x: right;
  padding:14px;

}

.left_svg,
.left_svg_full {
  color:#000;
  background-image: url('../svg/left.svg');
}

.grey_left_svg,
.grey_left_svg_full {
  color:#FFF;
  background-image: url('../svg/grey_left.svg');
}

.right_svg {
  background-image: url('../svg/right.svg');
}
.grey_right_svg {
  background-image: url('../svg/grey_right.svg');
}

.blue_left_svg,
.blue_left_svg_full
{
  color:#FFF;
  background-image: url('../svg/blue_left.svg');
}

.dark_blue_left_svg,
.dark_blue_left_svg_full
{
  color:#FFF;
  background-image: url('../svg/dark_blue_left.svg');
}

.blue_right_svg {
  background-image: url('../svg/blue_right.svg');
}
.dark_blue_right_svg {
  background-image: url('../svg/dark_blue_right.svg');
}


.left_indent p, .left_indent h1, .left_indent h2, .left_indent h3, .left_indent h4, .left_indent h5, .left_indent h6 {
  padding-left: 20px;  
}


.grey_left_svg h4,
.grey_left_svg_full h4 {
  margin-top:3px;
}


/* FORM STUFF */
.form-control {
  background-color: #FFF;
}
.form-control:focus {
  background-color: #FFF;
}
.form-control.textarea.readonly {
  color: grey;
}

.an_error {
  color: var(--brand-red);
}
.small_error_text {
  line-height: 1.2em;
  font-size: 15px;
  font-style: italic;
}

.form_error_message {
  color: var(--brand-blue);
}
.red_block .an_error {
  color:#FFF;
}
.red_block .form_error_message {
  color:#FFF;
}
.react-datepicker-wrapper input {
    
    color: #000;
    
    background-color: #FFF;
    width: 100%;
    padding: 0.375rem 0.75rem;
    
    border: 1px solid #ced4da;
    border-radius: 0.375rem;

}

.hidden {
  display: none;
}

@media (max-width: 980px) {

  .page {
    width: 100%;
  }

  .page_regular {
    width: calc(100% - 40px);
  }


  .center_block_content {
    width:100%;
  }

  .left_svg h2,
  .left_svg_full h2,
  .blue_left_svg h2,
  .blue_left_svg_full h2,
  .dark_blue_left_svg h2,
  .dark_blue_left_svg_full h2 {
    margin-top:4px;
    font-size:22px;
  }

  .left_svg h3,
  .left_svg_full h3,
  .blue_left_svg h3,
  .blue_left_svg_full h3,
  .dark_blue_left_svg h3,
  .dark_blue_left_svg_full h3 {
    margin-top:4px;
    font-size:20px;
  }

  .left_svg h4,
  .grey_left_svg h4,
  .grey_left_svg_full h4,
  .left_svg_full h4,
  .blue_left_svg h4,
  .blue_left_svg_full h4,
  .dark_blue_left_svg h4,
  .dark_blue_left_svg_full h4 {
    margin-top:5px;
    font-size:18px;
  }


}
