#map {
	width:100%;
	height: 300px;
}


.left_indent#map {
	padding-left:20px;
}


.right_map {
	width: 50%;
	display: inline-block;
}

.left_address {
	width: 50%;
	display: inline-block;
	vertical-align: top;
	padding: 14px 20px;
	text-align: left;
}



@media (max-width: 980px) {


	.left_map {
		width: 100%;
		display: inline-block;
	}

	.right_address {
		width: 100%;
		display: inline-block;
		vertical-align: top;
		padding: 14px 20px;
		text-align: center;
	}




}
