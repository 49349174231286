
.data_center {

  background-image: url('../img/datacenter2.jpg');
  background-repeat: no-repeat;
  background-position-y: -200px;
  height: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px);
  width:calc(100%);
  background-position-x:  calc(70px + (5000px - 100vw) * -0.421);
  background-size: calc(100% + (5000px - 100vw) * 0.8);

}



.page_label_blurb {
  text-align: left;
  position: absolute;
  margin-left: 0px;
  height:56px;
  top: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - 56px);
  color:#FFF;
  padding-left:20px;
}

@media (max-width: 980px) {


  .data_center {
    background-position-x: calc(50.5% + (1px - (100vw / 980)) * 18);
    background-size: calc(100vw / 980 * 3215 + 100%);
    background-position-y:calc( 100vw / 980 * -200 + (100 * (1px - 100vw / 980)) );
    height: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - (268 * (1px - (100vw / 980))));
  }


  .page_label_blurb {
    top: calc(100vw / var(--main-image-width) * 400 + 1000px - 400px - (268 * (1px - (100vw / 980))) - 56px);
  }


}
